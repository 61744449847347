<template>
	<div class="main-contents">
		<div class="mem_container">
			<div class="mem_agree_contents">
				<div class="mem_contents_header">
					<div class="title"><span class="mem_info"></span><p>회원가입/{{mberInfo.title}}</p></div>
					<div class="reg-step"><img src="/images/mem/process_bar_02.png" alt="절차"/></div>
				</div>
				<div class="mem_contents_body" v-for="stipMap in stipList" :key="stipMap.stipDivCd">
					<div class="body-top" :class="stipMap.stipDivCd != '01' ? 'hr' : ''"><hr/><p>하이프로 {{mberInfo.mberDivCd == '11' ? '개인회원' : '기업회원'}} {{stipMap.stipNm}}</p><hr/></div>
					<div v-html="stipMap.stipCont"></div>
					<div class="agree-check"><input type="checkbox" :id="'chk'+stipMap.stipDivCd" v-model="stipMap.agreeYn"> <label :for="'chk'+stipMap.stipDivCd">하이프로 {{stipMap.stipNm}}을(를) 확인하였고, 이에 동의합니다.</label> </div>
				</div> 	
				
			</div>
			<div class="select-btn">
				<div class="select_btn01" @click="btnCancel()">동의하지 않음</div>
				<div class="select_btn02" @click="btnSubmit()">동의함</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			mberInfo : {
				mberDivCd : '',
				title : '기술인재',
				stipList : []	
			},
			stipList : [],
			forwardData : this.$route.params.forwardData
		}
	},

	beforeMount() {
		if(this.forwardData) {
			this.setMberInfo();
			this.selectStipCont();

			if(this.mberInfo.mberDivCd == '21') {
				this.mberInfo.title = '수요기업_관리총괄';
			}else if(this.mberInfo.mberDivCd == '22') {
				this.mberInfo.title = '수요기업_프로젝트 담당';
			}else if(this.mberInfo.mberDivCd == '31') {
				this.mberInfo.title = '공급기업';
			}


		}else {
			this.$router.replace('MEM003M01');
		}
	},

	methods : {
		setMberInfo() {
			//console.log('forwardData :', this.forwardData);

			this.mberInfo.mberDivCd = this.forwardData.mberDivCd;
		},

		selectStipCont() {
			this.$.httpPost('/api/mem/selectStipCont', {mberDivCd : this.mberInfo.mberDivCd}, '')
				.then(res => {
					if(res.data.resultCode == '000') {
						//console.log('stipList :' ,res.data.data.stipList);

						this.stipList = res.data.data.stipList;
					}
				});
		},
		
		btnCancel() {
			this.$router.push('MEM003M01');
		},
		
		btnSubmit() {
			for(var i in this.stipList) {
				if(this.stipList[i].mandYn == 'Y' && !this.stipList[i].agreeYn) {
					alert('\'' + this.stipList[i].stipNm + '\'은(는) 필수약관 입니다.');
					return false;
				}

				this.mberInfo.stipList[i] = {
					stipDivCd : this.stipList[i].stipDivCd,
					stipVer : this.stipList[i].stipVer,
					agreeYn : this.stipList[i].agreeYn ? 'Y' : 'N'
				}
			}

			this.$router.push({name : 'MEM004M02', params : {forwardData : this.mberInfo}});
			//this.$router.push({name:'MEM004M03', params : {forwardData : this.mberInfo}});
		}
	}
}
</script>